// @font-face {
	// font-family: 'Montserrat';
	// src: url('fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
	// 	 url('fonts/montserrat/Montserrat-Regular.woff') format('woff');
	// font-weight: 100;
	// font-style: normal;
//}
@font-face {
	font-family: 'SFUIText';
	src: url('fonts/SFFont/SF-UI-Text-Regular.otf') format('otf');
		 //url('fonts/SF-UI-Text-Regular.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}