// - - - - - - - - - - - - - - - - - - -
// - - pages
/*---------------------------------------
    General Style             
-----------------------------------------*/
@font-face {
	font-family: 'SFUIText';
	src: url('../fonts/SFFont/SanFranciscoText-Regular.otf') format('opentype');
   // src: url('assets/fonts/SFFont/system_san_francisco_display_regular-webfont.eot') format('opentype');
   // src: url('assets/fonts/SFFont/system_san_francisco_display_regular-webfont.ttf') format('opentype');
   // src: url('assets/fonts/SFFont/system_san_francisco_display_regular-webfont.woff') format('opentype');
   // src: url('assets/fonts/SFFont/system_san_francisco_display_regular-webfont.svg') format('opentype');
}

// div .navbar .navbar-default .navbar-fixed-top{
// 	width: 100%;
// 	max-width: 1440px;
// }

body{
	background-color:#fff;
	font-family:Montserrat;
	color:#fff;
	max-width: 2600px;
	width: 100%;
	margin:auto;
	overflow-x: hidden;
}

a,a:visited,a:hover{
	text-decoration:none;
	//color:white;
	color: #0d536e;
}

h3,p{
	margin-bottom:30px;
}
#wrap{
	//margin:0 15px 0 15px;
}

.boxContent{
	margin:0 auto;
	max-width:1200px;
}

.appsLink a img{
	margin-right:10px;
	width:120px;
}

.appsimg{
	padding-top:30px;
}

.logoArea{
	padding-top:100px;
	&.right{
		text-align:right;
	}
	img{
		width:150px;
		margin-bottom:5px;
	}
}

.textArea{
	padding-top:60px;
	&.left{
		padding-left:40px;
	}
	h3{
		margin-top:40px;
		font-size:23px;
		
	}
	h6{
		margin-top:40px;
		font-size:10px;
	}
	p{
		margin-top:20px;
		font-size:13px;
		font-weight:normal;
	}
	span{
		color:#000;
		font-weight:bold;
		padding-top:50px;
	}
}

#section1{
	// padding-top:100px;
	padding-top: 13%;
	height: 100%;
	// margin-bottom:35px;
	min-height: 100vh;
}
#section1-help{
	padding-left: 20px;
	padding-top: 5%;
	height: 100%;
	// margin-bottom:35px;
	min-height: 60vh;
	padding-right: 20px;
}
#section2{
	// padding-top:100px;
	// padding-top: 10%;
	height: 100%;
	min-height: 100vh;
	// margin-bottom:50px;
}
#section2-help{
	height: auto;
	// padding-left: 30px;
	// padding-right: 25px;
}
#section3-help{
	height: auto;

}
#section4{
	// padding-top:100px;
	// padding-top: 10%;
	height: 100%;
	min-height: 50vh;
	margin: auto;
	//min-height: 100vh;
	// margin-bottom:50px;
}
#section5{
	// padding-top:100px;
	// padding-top: 10%;
	height: 50%;
	min-height: 50vh;
	margin: auto;
	//min-height: 100vh;
	// margin-bottom:50px;
}

/*---------------------------------------
    Navigation section              
-----------------------------------------*/

.navbar-default {
	background: #f6f8fb;
	border: none;
	box-shadow: none;
	margin: 0 ;
}

.navbar-default .navbar-brand {
	color: #535bd4;
	font-size: 26px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	line-height: 50px;
	margin: 0;
}

.navbar-default .navbar-brand span {
	color: #1dc9e3;
}

.navbar-default .navbar-nav li a {
	color: #60909f;
	font-size: 12px;
	font-weight: bold;
	line-height: 20px;

	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;

}

.navbar-default .navbar-nav > li a:hover {
	color: #1dc9e3;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: #656565;
	background-color: transparent;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: #60909f;
	background-color: transparent;
}

.navbar-default .navbar-toggle {
	border: none;
	padding-top: 10px;
}

.navbar-default .navbar-toggle .icon-bar {
	border-color: transparent;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus { 
	background-color: transparent;
}

/*---------------------------------------
   Main section              
-----------------------------------------*/

// 
#section1{
	// background: url('../images/netz/b-g.png') no-repeat;
 //    background-size: cover;
	// background-position:center;
	background-color: #f6f8fb;
}
#section1-help{
	// background: url('../images/netz/b-g.png') no-repeat;
 //    background-size: cover;
	// background-position:center;
	background-color: #f6f8fb;
}
//
#section2{
	// background: url('../images/zulu/b-g.png') no-repeat;
	// background-size: cover;
}

// 
#section3{
// 	background: url('../images/netconnect/b-g.png') no-repeat;
//     background-size: cover;
background-color: #f6f8fb;
height: 100%;
min-height: 100vh;
padding-top: 8%; 
}
// 
#section4{
	background: url('../images/bckgrnd-submitemail.jpg') no-repeat;
	background-size: cover;
}

// 
#section5{
	// background: url('../images/netcj/b-g.png') no-repeat;
 //    background-size: cover;
}




/*---------------------------------------
   Footer section              
-----------------------------------------*/

footer{
	background-color: #f6f8fb;
	padding-top:80px;
	padding-bottom:80px;
	h5{
		color:#4a4a4a;
		margin-top:30px;
		font-size:11px;
	}
}

.boxFooter{
	margin:0 auto;
	max-width:950px;
	h5{
		color:#000;
		font-size:10px;
		line-height:12px;
	}
	h6{
		color:#000;
		font-size:9px;
		margin-top:40px;
	}
}
/*---------------------------------------
   List Menu            
-----------------------------------------*/

.listFoot{
	position:relative;
	padding:0;
}
.listFoot li{
	margin-bottom:10px;
	list-style:none;
}
.listFoot li a{
	list-style:none;
	color:black;
	font-weight:bold;
}

/*---------------------------------------
   Social icon             
-----------------------------------------*/
.areaSocial{
	position: relative;
	span{
		color:black;
		font-weight:bold;
		font-size:15px;
		text-align:left;
		padding:0;
	}
}
.social-icon {
	padding: 0;
	margin: 0;
	text-align: left;
	margin-top:20px;
}

.social-icon li {
	display: inline-block;
	list-style: none;
}

.social-icon li a {
	color: #666;
	border-radius: 100px;
	cursor: pointer;
	font-size: 16px;
	text-decoration: none;
	transition: all 0.4s ease-in-out;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	vertical-align: middle;
	position: relative;
	top: 0px;
	margin: 10px 4px 0px 1px;
}

.social-icon li a:hover {
	top: -5px;
}

/*---------------------------------------
   Back top         
-----------------------------------------*/

.go-top {
	background-color: red;
	box-shadow: 1px 1.732px 12px 0px rgba( 0, 0, 0, .14 ), 1px 1.732px 3px 0px rgba( 0, 0, 0, .12 );
	border-radius: 100px;
	transition : all 1s ease;
	bottom: 2em;
	right: 2em;
	font-size: 22px;
	display: none;
	position: fixed;
	text-decoration: none;
	width: 35px;
	height: 35px;
	line-height: 30px;
	text-align: center;
}

.go-top:hover {
	background: #535bd4;
	color: #ffffff;
}

#return-to-top {
	position: relative;
	background: #fff;
	width: 48px;
	height: 48px;
	display: block;
	-webkit-border-radius: 35px;
	-moz-border-radius: 35px;
	border-radius: 35px;
	left: 2%;
	margin: auto;
	top: -25px;
	display: none;
}
#return-to-top i {
	color: #fff;
	margin: 0;
	position: relative;
	left: 16px;
	top: 13px;
	font-size: 19px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
#return-to-top:hover {
	background:#fff;
}
#return-to-top:hover i {
	color: #fff;
	top: 5px;
}
.icon-top{
	margin: auto;
	top: 19px;
	position: absolute;
	left: 15px;
}

/*---------------------------------------
   Mobile / Responsive Area        
-----------------------------------------*/

@media only screen 
and (min-device-width : 320px)
and (max-device-width : 480px) {
	#section2-help{
		padding: 0 20px;
	}
	.hp_logo-mobile{
		text-align: center;
		margin: auto;
	}
	.navbar-collapse-mobile{
		position: static;
		width: 100vw;
		height: 100vh;
		//padding-bottom: 450px;
		overflow-y: hidden !important;
	}
	.container-mobile{
		height: 100vh;
	}
	.nav-mobile{
		position: relative;
	}
	#section2,#section4{
		margin-bottom:35px;
	}
	.appsimg {
		width: 70%;
		margin: 0 auto;
	}
	.logoArea{
		padding-top:0;
	}
	.appsLink a img{
		margin-right:10px;
		width:100px;
	}
	.textArea{
		padding-top:0;
		&.left{
			padding-left:0!important;
		}
		h3{
			font-size:30px;
		}
		p{
			font-size:15px;
		}
	}
	.image{
		height: 250px;
		width: 235px;
	}
	.center_mob{
		text-align: center;
	}
	.mobile_pricing{
		width: auto;
	}
	.pf_mob{
		font-size: 12px;
	}
	.dontl_mob{
		font-size: 22px;
	}
	.dontlosetext_mob{
		padding-top: 33px;
		//font-size: 12px;
	}
	.dontlose_mob{
		width: 285px;
	}
	.mobile_pading{
		padding-bottom: 75px;
	}
	.links{
		width: 50%;
		float: left;
	}
	.getintouch{
		width: 50%;
		float: right;
	}
	#return-to-top{
		display: block !important;
	}
	.not_visible {
		display: inline;
	}  
	.visi{
		display: none;
	}
	.navbar-brand-mob{
		float: left;
		padding: 40px 15px;
		font-size: 18px;
		line-height: 20px;
		height: 50px;
		margin-left: 50%;
		margin: auto
	}
	.logo-float{
		margin-left: 100%;
	}
	.icon-mob{
		margin-left: 100px;
	}
	.footisi_mob{
		text-align: center;
	}
	.icon_c_mob{
		margin-left: -125px;
	}
	.foter_titlemob {
		font-family: SFUIText;
		color: #3bbaf4;
		font-size: 10px;
		text-align: left;
		padding-left: 15px;
		font-weight: 600;
		padding-top: 0 !important;
	}
	.email_contacmob {
		font-family: SFUIText;
		font-size: 10px;
		font-weight: 600;
		text-align: left;
		color: #3bbaf4;
		padding-left: 15px;
	}
	.help-text-mobile{
		font-family: SFUIText;
		font-size: 14px !important;
		line-height: 1.29;
		letter-spacing: 0.2px;
		text-align: left;
		color: #60909f;
	}
	.font-acordion{
		font-size: 11px !important;
	}
	.font__{
		font-size: 5px !important;
	}
	#section1-help{
		min-height: 54vh !important;
		margin-bottom: 35px !important;
	}
	.tab-text{
		font-size: 11px !important;
	}
	.tab-active{
		height: 32px;
		font-size: 11px;
	}
	.form-accordion{
		font-size: 10px !important;
	}
	.download-{
		font-size: 10px !important;
	}
	.image_tabing{
		padding-top: 0 !important;
		width: 100%;
	}
	.navbar-brand{
		padding-left: 35px !important;
	}
	.card-tab{
		height: 100px !important; 
		width: 80px !important; 
	}
	.card{
		padding-right: 0;
		padding-left: 0;
		margin-left: -15px;
		margin-right: -20px;
	}
	.pagination a{
		padding: 2px 6px;
	}
}

.not_visible {
	display: none;
}
.visi{
	display: inline;
}

@media only screen 
and (min-device-width : 481px) 
and (max-device-width : 767px) {
	#section2,#section4{
		margin-bottom:35px;
	}
	.boxContent{
		padding:30px;
	}
	.appsimg {
		width: 50%;
		margin: 0 auto;
	}
	.logoArea{
		padding-top:0;
	}
	.appsLink a img{
		margin-right:10px;
		width:150px;
	}
	.textArea{
		padding-top:0;
		&.left{
			padding-left:0!important;
		}
		h3{
			font-size:30px;
		}
		p{
			font-size:15px;
		}
	}
}

/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 980px) {

	.appsimg {
		width: 50%;
		margin: 0 auto;
	}
	.logoArea{
		padding-top:0;
	}

	.textArea{
		padding-top:0;
		&.left{
			padding-left:0!important;
		}
		h3{
			font-size:30px;
		}
		p{
			font-size:15px;
		}
	}
}



/* iPads (Landscape) ----------- */
@media only screen 
and (min-device-width : 980px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
	#section1.logoArea,
	#section2 .logoArea,
	#section3 .logoArea{
		padding-left:10px;
	}
	#section2 .appsimg,
	#section4 .appsimg{
		padding-left:10px;
	}

}
.wiQ{
	font-family: Montserrat;
	font-size: 24px;
	text-align: left;
	color: #0d536e;
}
.wiQ-content{
	font-family: SFUIText;
	font-size: 18px !important;
	line-height: 1.33;
	text-align: left;
	color: #60909f;
}

.btn-get{
	border-radius: 53px;
	background-color: #3bbaf4;
	font-family: Montserrat;
	font-size: 14px;
	text-align: center;
	color: #ffffff;
	border: none;
	padding: 8px 20px;
	position: relative;
	box-shadow: 0 5px 23px 0px #3bbaf4;
	outline: none;
}
.btn_signin{
	border-radius: 54px;
	border: solid 1px #3bbaf4;
	font-family: Montserrat;
	font-size: 12px;
	text-align: left;
	color: #3bbaf4;
	background: transparent;
	padding: 5px 22px;
	outline: none;
}
.qip{
	font-family: Montserrat;
	font-size: 22px;
	color: #0d536e;
	text-align: center;
	font-weight: 500;
	width: fit-content;
	margin: auto;
	&:after {
		content: '';
		position: absolute;
		width: 15px;
		height: 3px;
		background: #3bbaf4;
		left: 50%;
		bottom: -35%;
		transform: translateX(-50%);
	}
}

.qip_text{
	font-family: SFUIText;
	font-size: 18px;
	line-height: 1.33;
	text-align: center;
	color: #60909f;
	padding-top: 5%;
}
.qip_img_pos{
	text-align: center;
	padding-top: 5%;
}
.qip_title{
	font-family: Montserrat;
	font-size: 18px;
	text-align: center;
	color: #0d536e;
	padding-top: 3%;
	font-weight: 500;
}
.qipt_desc{
	font-family: SFUIText;
	font-size: 14px;
	line-height: 1.71;
	text-align: center;
	padding-top: 5%;
	color: #60909f;
}
.pricingbox{
	width: max-content;
	height: auto;
	border-radius: 4px;
	background-color: #ffffff;
	border: solid 1px #e6e9ee;
	padding: 40px 90px;
	margin: auto;
	margin-top: 10%;
}
.p_free{
	font-family: Montserrat;
	font-size: 30px;
	line-height: 1;
	text-align: center;
	color: #3bbaf4;
	padding-bottom: 15%;
}
.pf_text{
	font-family: SFUIText;
	font-size: 14px;
	line-height: 1.71;
	text-align: center;
	color: #60909f;
}
.sc4_title{
	font-family: Montserrat;
	font-size: 27px;
	text-align: center;
	color: #ffffff;
	text-align: center;
	font-weight: 500;
	width: fit-content;
	margin: auto;
	&:after {
		content: '';
		position: absolute;
		width: 15px;
		height: 3px;
		background: #3bbaf4;
		left: 50%;
		bottom: -35%;
		transform: translateX(-50%);
	}
	padding-top: 5%;
}
.sc4_isi{
	font-family: SFUIText;
	font-size: 14px;
	line-height: 1.71;
	text-align: center;
	padding-top: 5%;
	color: #ffffff;
}
.input_color{
	color: #60909f;
	width: 360px;
	height: auto;
	border: none;
	min-height: 38px;
	border-radius: 100px;
	background-color: #ffffff;
	padding-left: 15px;
}
.btn-submit{
	position: absolute;
	right: 15px;
	height: auto;
	min-height: 38px;
	outline: none;
}
.groupinput-text{
	position: relative;
	display: table;
	width: 100%;
	max-width: 360px;
	left: 50%;
	transform: translateX(-50%);
}
.foter_isi{
	font-family: SFUIText;
	font-size: 11px;
	line-height: 1.5;
	text-align: left;
	color: #60909f;
	// padding-top: 25%;
	// padding-left: 15px;
}
.footer-soc{
	display: inline-flex;
	width: 100%;
}
.iconfacebook{
	margin-left: -40px;
	padding-right: 20px;
}
.icon_c{
	color: #0d536e;
	// padding-top: 25%;
	// margin-left: -109px;
}
.copyr{
	font-family: SFUIText;
	font-size: 9px;
	text-align: left;
	color: #0d536e;
	padding-left: 15px;
	margin-top: -16px;
}
.foter_title{
	font-family: SFUIText;
	color: #0d536e;
	font-size: 13px;
	text-align: left;
	padding-top: 7%;
	padding-left: 15px;
	font-weight: 600;
	padding-top: 7%;
}
.f0oter_isi{
	font-family: SFUIText;
	font-size: 11px;
	line-height: 1.5;
	text-align: left;
	color: #60909f;
	line-height: 2.2;
	padding-left: 15px;
}
.email_contac{
	font-family: SFUIText;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	color: #3bbaf4;
	padding-left: 15px;
}
/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1224px) {
/* Styles */
}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
/* Styles */
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
/* Styles */
}
// - - home styles - a unique styles that implemented in a specified pages

//help 
.help-center{
	font-family: Montserrat;
	font-size: 22px;
	text-align: left;
	color: #0d536e;
	font-weight: 500;
	//width: fit-content;
	margin: auto;
	&:after {
		content: '';
		position: absolute;
		width: 15px;
		height: 3px;
		background: #3bbaf4;
		left: 25px;
		bottom: 76%;
		transform: translateX(-50%);
	}
}
#myInput {
	background-image: url('../images/icon-search.svg');
	background-position: 10px 12px;
	background-repeat: no-repeat;
	width: 100%;
	font-size: 16px;
	padding: 12px 20px 12px 40px;
	border: 1px solid #ddd;
	margin-bottom: 12px;
	color: #0d536e;
}

#myUL {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

#myUL li a {
	border: 1px solid #ddd;
	margin-top: -1px; 
	background-color: #f6f6f6;
	padding: 12px;
	text-decoration: none;
	font-size: 18px;
	color: black;
	display: block
}

#myUL li a.header {
	background-color: #e2e2e2;
	cursor: default;
}

#myUL li a:hover:not(.header) {
	background-color: #eee;
}
.card{
	margin: auto;
	position: relative;
	top: 80px;
	padding-left: 150px;
	padding-right: 150px;
}
.card-tab{
	width: 192px;
	height: 172px;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 2px 2px 12px 0 rgba(203, 211, 226, 0.15);
	border: solid 1px #e6e9ee;
	position: relative;
	text-align: center;
}
.tab-text{
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 600;
	text-align: center;
	color: #3bbaf4;
}
.basic-title{
	// width: 56px;
	padding-top: 30px;
	font-family: Montserrat;
	font-size: 18px;
	text-align: left;
	color: #0d536e;
	font-weight: 600;
}
.form-accordion{
	width: 100%;
	height: auto;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 2px 2px 12px 0 rgba(203, 211, 226, 0.15);
	border: solid 1px #e6e9ee;
	padding: 15px 25px;
	margin-top: 15px;
	font-family: SFUIText;
	font-size: 16px;
	text-align: left;
	color: #60909f;
}
.download-{
	font-family: Montserrat;
	font-size: 18px;
	float: right;
	color: #3bbaf4;
	// font-weight: 600;
}
.tab-active{
	border-bottom: 3px solid;
	content: '';
	position: absolute;
	bottom: 0px;
	margin: auto;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 65px;
}
.contactus{
	font-family: Montserrat;
	font-size: 14px;
	text-align: left;
	color: #60909f;
	padding: 10px 0;
}
.form-contactus{
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 2px 2px 12px 0 rgba(203, 211, 226, 0.15);
	border: solid 1px #e6e9ee;
	padding: 5px 15px;
	width: 100%;
	font-family: SFUIText;
	font-size: 16px;
	text-align: left;
	color: #60909f;
}
.font-acordion{
	font-family: SFUIText;
	font-size: 16px !important;
	text-align: left;
	color: #60909f !important;
}
.back_det{
	font-family: Montserrat;
	font-size: 16px;
	text-align: left;
	color: #3bbaf4 !important;
	//padding-left: 15px;
}
.detail_title{
	font-family: Montserrat;
	font-size: 18px;
	text-align: left;
	color: #0d536e;
	padding-top: 35px;
}
.detail_text{
	font-family: SFUIText;
	font-size: 14px;
	line-height: 1.71;
	text-align: left;
	color: #60909f;
	padding-top: 20px;
	text-align: justify;
}
.linkdetail{
	color: #3bbaf4 !important;
	border-bottom: 1px solid #3bbaf4;
}
.search-result{
	font-family: Montserrat;
	font-size: 14px;
	text-align: left;
	color: #60909f;
	padding-top: 15px;
}
.contakus{
	border-radius: 4px;
	background-color: #3bbaf4;
	box-shadow: 2px 2px 12px 0 rgba(203, 211, 226, 0.15);
	border: solid 1px #e6e9ee;
}
.kontakus{
	font-family: SFUIText;
	font-size: 16px;
	text-align: left;
	color: #ffffff;
}
.search_{
	font-family: Montserrat;
	font-size: 18px;
	text-align: right;
	color: #3bbaf4;
	position: absolute;
	margin-top: 13px;
	margin-left: -90px;
}
.search_teks{
	font-family: SFUIText !important;
	font-size: 16px !important;
	text-align: left;
	color: #0d536e !important;
}
//.tab-active{border-bottom:3px solid;content:'';position:absolute;bottom:0px;margin:auto;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);height:45px}


.thumb {
	height: 75px;
	border: 1px solid #000;
	margin: 10px 5px 0 0;
}
.fileContainer {
	font-weight: 500;
	text-align: center;
	line-height: 5em;
	width: 100%;
	max-width: 100%;
	color: #60909f;
	height: 70px;
	overflow: hidden;
	position: relative;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 2px 2px 12px 0 rgba(203, 211, 226, 0.15);
	border: solid 1px #e6e9ee;
}

.fileContainer [type=file] {
	cursor: inherit;
	display: block;
	font-size: 999px;
	filter: alpha(opacity=0);
	min-height: 100%;
	min-width: 100%;
	opacity: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
}
.style-btn{
	margin: auto;
	position: relative;
	bottom: -40px;
}
.delete-img{
	float: right;
	position: relative;
	padding-right: 15px;
}
.pagination {
	display: inline-block;
	padding-top: 35px;
	padding-bottom: 25px;
	margin-left: 50%;
	//margin: auto;
	transform: translateX(-50%);
}

.pagination a {
	padding: 8px 16px;
	font-family: Rubik;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	color: #60909f
}
.pagination_active{
	border-radius: 3px;
	background-color: #00c0f3;
	color: #fff !important;
}